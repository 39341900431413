export const DESCRIPTION_TEXT = {
    aboutProjectTextList: {
        title: 'about_us_project_title',
        textList: [
            { dictionaryKey: 'about_us_project_description_text_first'  },
            { dictionaryKey: 'about_us_project_description_text_last' },
        ],
    },
    securityText: {
        title: 'about_us_security_title',
        textList: [
            { dictionaryKey: 'about_us_security_description_first'  },
            { dictionaryKey: 'about_us_security_description_last'  },
        ],
    },
    bonusText: {
        title: 'about_us_bonuses_title',
        textList: [
            { dictionaryKey: 'about_us_bonuses_description_text_first'  },
            { dictionaryKey: 'about_us_bonuses_description_text_last' },
        ],
    },
    howStartText: {
        title: 'about_us_how_start_title',
        textList: [
            { dictionaryKey: 'about_us_how_start_description_text_first'  },
            { dictionaryKey: 'about_us_how_start_description_text_last' },
        ],
    },
};

export const ABOUT_US_IMAGES = {
    boxImg: {
        name: 'box',
        imgWidth: {
            desktop: 450,
            mobile: 300,
        },
    },
    girlImg: {
        name: 'girl',
        imgWidth: {
            desktop: 450,
            mobile: 328,
        },
    },
    aboutUsBanner: {
        name: 'bannerBag',
        imgWidth: {
            desktop: 1200,
            mobile: 338,
        },
    },
};